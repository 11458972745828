import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
    <div>
      <Layout siteTitle="Fehler">
        <p className="text-center">
          Die Seite konnte nicht gefunden werden
        </p>
      </Layout>
    </div>
  )
}